const routes = require("next-routes");

const makeOptionalLoggedIn = (pattern) => `/:prefix(candidate-zone)?${pattern}`;

const makeProtected = (pattern) => `/candidate-zone${pattern}`;

const publicRoutes = [
  {
    name: "home",
    pattern: "/",
    page: "index"
  },
  {
    name: "FreeOnlineCourses",
    pattern: "/online-courses/:slug/",
    page: "freeOnlineCourses"
  },
  {
    name: "loginRegistration",
    pattern: "/candidate/register/",
    page: "loginRegistration"
  },
  {
    name: "autoLogin",
    pattern: "/autologin/",
    page: "autoLogin"
  },
  {
    name: "logout",
    pattern: "/logout/",
    page: "logout"
  },
  {
    name: "jobsByCity",
    pattern: "/jobs-by-city/",
    page: "jobsByCity"
  },
  {
    name: "jobsByCompany",
    pattern: "/jobs-by-companies/",
    page: "jobsByCompany"
  },
  // {
  //   name: "sarkariJobsHome",
  //   pattern: "/sarkari-jobs-results/",
  //   page: "sarkariJobsHome"
  // },
  // {
  //   name: "sarkariJobsByProfile",
  //   pattern: "/sarkari-jobs-results/jobs-by-profile/",
  //   page: "sarkariJobsByProfile"
  // },
  // {
  //   name: "sarkariJobsLatest",
  //   pattern: "/sarkari-jobs-results/latest-:slugId/",
  //   page: "sarkariJobsLatest"
  // },
  // {
  //   name: "sarkariJobGroups",
  //   pattern: "/sarkari-jobs-results/jobs-by-:suffixGroup/",
  //   page: "sarkariJobGroups"
  // },
  // {
  //   name: "sarkariJobCombinedGroups",
  //   pattern: "/sarkari-jobs-results/:prefixGroup-jobs-by-:suffixGroup/",
  //   page: "sarkariJobGroups"
  // },
  // {
  //   name: "sarkariPopularJobGroups",
  //   pattern: "/sarkari-jobs-results/popular-jobs/",
  //   page: "sarkariPopularJobGroups"
  // },
  // {
  //   name: "sarkariJobsQuiz",
  //   pattern: "/sarkari-jobs-results/current-affairs-quiz/",
  //   page: "sarkariJobsQuiz"
  // },
  // {
  //   name: "quizRegistration",
  //   pattern: "/sarkari-jobs-results/reg-successful/",
  //   page: "quizRegistration"
  // },
  // {
  //   name: "unsubscribePage",
  //   pattern: "/sarkari-jobs-results/subscription/",
  //   page: "unsubscribePage"
  // },
  // {
  //   name: "sarkariJobsListing",
  //   pattern: "/sarkari-jobs-results/:slug/",
  //   page: "sarkariJobsListing"
  // },
  // {
  //   name: "sarkariJobsDetail",
  //   pattern: "/sarkari-jobs-results/job/:slug/:id/",
  //   page: "sarkariJobsDetail"
  // },
  // {
  //   name: "sarkariJobsDetailOld",
  //   pattern: "/sarkari-jobs-results/job/:id/",
  //   page: "sarkariJobsDetail"
  // },
  {
    name: "notificationConsent",
    pattern: "/notification-consent/",
    page: "notificationConsentPage"
  },
  {
    name: "termsOptOut",
    pattern: "/opt-out-terms/",
    page: "termsOptOutPage"
  },
  {
    name: "mobileVerification",
    pattern: "/mobile-verification/",
    page: "mobileVerificationPage"
  },
  {
    name: "announcement",
    pattern: "/aj-to-waah/",
    page: "announcementPage"
  }
];

const optionalLoggedInRoutes = [
  {
    name: "jobFilter",
    pattern: "/jf/:slug/",
    page: "jobFilters"
  },
  {
    name: "similarJobs",
    pattern: "/job/:slug/:id/similar-jobs/",
    page: "similarJobs"
  },
  {
    name: "similarJobsOld",
    pattern: "/job/:id/similar-jobs/",
    page: "similarJobs"
  },
  {
    name: "jobDetail",
    pattern: "/job/:slug/:id/",
    page: "jobDetail"
  },
  {
    name: "GigsJobDetail",
    pattern: "/gigs-job/:slug/:id/",
    page: "GigsJobDetail"
  },
  {
    name: "gigApplication",
    pattern: "/gigApplication/:jobId/",
    page: "gigApplication"
  },
  {
    name: "jobDetailOld",
    pattern: "/job/:id/",
    page: "jobDetail"
  },
  {
    name: "orgDetail",
    pattern: "/org/:slug/:id/",
    page: "organizationDetail"
  },
  {
    name: "orgDetailOld",
    pattern: "/org/:id/",
    page: "organizationDetail"
  },
  {
    name: "functionalArea",
    pattern: "/jobs-by-categories-and-designation/",
    page: "functionalareas"
  },
  {
    name: "functionalArea1",
    pattern: "candidate-zone/jobs-by-categories-and-designation/",
    page: "functionalareas"
  },
  {
    name: "jobList",
    pattern: "/s/:slug([A-Za-z0-9-]+)/",
    page: "joblist"
  },
  {
    name: "exploreJobList",
    pattern: "/explore-jobs/",
    page: "exploreJoblist"
  },
  {
    name: "jobSearch",
    pattern: "/search/:slug?/",
    page: "jobsearch"
  },
  {
    name: "locationIndex",
    pattern: "/locationindex/:slug?/",
    page: "locationindex"
  },
  {
    name: "organizationList",
    pattern: "/organizationlist/",
    page: "organizationList"
  },
  {
    name: "staticPages",
    pattern: "/q/:slug([a-z0-9-]+)/",
    page: "staticPages"
  },
  {
    name: "companyPages",
    pattern: "/company/:slug/",
    page: "companyPages"
  },
  {
    name: "knowledgeBasePage",
    pattern: "/knowledge-base/job-seeker/",
    page: "knowledgeBasePage"
  },
  {
    name: "knowledgeBase",
    pattern: "/knowledge-base/job-seeker/:slug/",
    page: "knowledgeBase"
  },
  {
    name: "sitemap",
    pattern: "/sitemap/",
    page: "sitemap"
  },
  {
    name: "privacyPolicy",
    pattern: "/privacy/",
    page: "privacyPolicy"
  },
  {
    name: "termsPage",
    pattern: "/terms/",
    page: "termsPage"
  },
  {
    name: "faq",
    pattern: "/employee/faq/",
    page: "faq"
  },
  {
    name: "contactUs",
    pattern: "/contact/",
    page: "contactUs"
  },
  {
    name: "aboutUs",
    pattern: "/about-us/",
    page: "aboutUs"
  },
  {
    name: "helpdesk",
    pattern: "/helpdesk/",
    page: "helpdesk"
  }
];

const protectedRoutes = [
  {
    name: "profile",
    pattern: "/profile/",
    page: "profile"
  },
  {
    name: "editProfile",
    pattern: "/edit/:section/",
    page: "editProfile"
  },
  {
    name: "editUserLocation",
    pattern: "/editlocation/",
    page: "editUserLocation"
  },
  {
    name: "applications",
    pattern: "/applications/",
    page: "applications"
  },
  {
    name: "matchingJobs",
    pattern: "/matching-jobs/",
    page: "matchingJobs"
  },
  {
    name: "appTracker",
    pattern: "/applications/:slug/",
    page: "appTracker"
  },
  {
    name: "interviewDetail",
    pattern: "/interviewdetail/:slug/",
    page: "interviewdetail"
  },
  {
    name: "fixInterview",
    pattern: "/fixinterview/:slug/",
    page: "fixinterview"
  },
  {
    name: "dashboard",
    pattern: "/dashboard/",
    page: "dashboard"
  },
  {
    name: "verify",
    pattern: "/verify/:page/",
    page: "verification"
  },
  {
    name: "apply-job",
    pattern: "/apply-job/",
    page: "applyToJob"
  },
  {
    name: "offerletter",
    pattern: "/offer-letter/",
    page: "offerletter"
  },
  {
    name: "applicationForm",
    pattern: "/application-form/:jobId/",
    page: "applicationForm"
  },
  {
    name: "interviewPreparation",
    pattern: "/interview-preparation/:jobId",
    page: "interviewPreparation"
  },
  {
    name: "postJoining",
    pattern: "/post-joining-info/:id/",
    page: "postJoiningPage"
  },
  {
    name: "enable-whatsapp",
    pattern: "/enable-whatsapp/",
    page: "enableWhatsapp"
  },
  {
    name: "resumeForm",
    pattern: "/resume-form/:jobId/",
    page: "resumeForm"
  },
  {
    name: "deleteAccount",
    pattern: "/deleteAccount/",
    page: "deleteAccount"
  },
  {
    name: "editCategories",
    pattern: "/edit-categories/",
    page: "editCategories"
  },
  {
    name: "cmsForm",
    pattern: "/cms-form/:jobId/",
    page: "cmsForm"
  },
  {
    name: "smartProfileForm",
    pattern: "/smart-profile-form/",
    page: "smartProfileForm"
  },
  {
    name: "shareResume",
    pattern: "/share-resume/",
    page: "shareResume"
  },
  {
    name: "reportJob",
    pattern: "/report-job/",
    page: "reportJob"
  },
  {
    name: "gigListing",
    pattern: "/gigs-jobs/",
    page: "gigJob"
  },
  {
    name: "gigEarnings",
    pattern: "/gigs-earning/",
    page: "gigEarnings"
  },
  {
    name: 'gigApplicationUpload',
    pattern: "/gig-application-upload",
    page: 'gigApplicationUpload',
  },
  {
    name: "gigAllPaidEarnings",
    pattern: "/gigs-all-earning/",
    page: "gigAllPaidEarnings"
  },
  // TODO: Need to Fix URL name's for now added account details
  {
    name: "bankAccountDetails",
    pattern: "/account-details/",
    page: "editBankAccountDetails"
  },
];

const myRoutes = routes()
  .add({
    name: "serviceWorker",
    pattern: "/service-worker.js"
  })
  .add({
    name: "loggedInServiceWorker",
    pattern: "/protected-service-worker.js"
  })
  .add({
    name: "oldMWServiceWorker",
    pattern: "/candidate-zone/sw.js"
  })
  .add({
    name: "placeDetails",
    pattern: "/place/:placeId"
  })
  .add({
    name: "userTokenInfo",
    pattern: "/token-info/:idToken"
  })
  .add({
    name: "sitemapXML",
    pattern: makeOptionalLoggedIn("/sitemap.xml")
  });

publicRoutes.forEach((routeObj) => myRoutes.add(routeObj));

optionalLoggedInRoutes.forEach((routeObj) =>
  myRoutes.add({
    ...routeObj,
    pattern: makeOptionalLoggedIn(routeObj.pattern)
  })
); // make each of the optional routes so

protectedRoutes.forEach((routeObj) =>
  myRoutes.add({
    ...routeObj,
    pattern: makeProtected(routeObj.pattern)
  })
); // add a candidate-zone prefix to each protected route

module.exports = myRoutes;
